<template>
    <div class="about">
        <header-layout class="h" :no_bg="true"/>
        <div class="main">
            <head-layout class="he" :text="'About'"/>

            <div class="about_text">
                Talking realty, a stress-less and seamless transaction is the reason We were born. 
                From property search, rentals, sales and procurement, to facility management both 
                online and offline to ensure your peace of mind and more money in your bank account 
                is all We live for. With Us, you can now; earn where you are and stay where you 
                want when you want . It is safe to call Us an all-in-one realty solutions provider.
            </div>
        </div>
        <register/>
        <footer-layout/>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'
import headLayout from '../components/head.vue'
import register from '../components/register.vue'
import footerLayout from '../components/footer.vue'

export default {
    components: {
        headerLayout,
        headLayout,
        register, footerLayout
    }
}
</script>

<style scoped>

    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    .main {
        max-width: 1000px;
        margin: 0 auto;
        padding: 0px 20px;
        padding-bottom: 200px;
        padding-top: 200px;
    }
    .he {
        margin: 40px 0px;
        margin-top: 20px;
    }

    .about_text {
        line-height: 30px;
        /* font-size: 18px; */
    }

    @media screen and (max-width: 600px) {
        .main {
            padding: 80px 20px;
            padding-top: 120px;
        }
    }
</style>